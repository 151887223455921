import { LocalizedText } from '../utils/common-types';
import { API_URL } from '../utils/constants';
import { resolveBaseUrl } from './helpers';

interface UnsubscribePayload {
  externalId: string;
  token: string;
  channel?: string
}

class ContactService {

  constructor () {
    this.baseUrl = resolveBaseUrl();
  }

  baseUrl = API_URL;

  unsubscribe = async (externalId: string, token: string, channel?: string): Promise<LocalizedText> => {
    // TODO: Sustituir por mensaje desde server
    let resultMessage: LocalizedText = {
      default: `Success. You will not receive surveys anymore.`,
      es: `Operación exitosa. Ya no recibirá más encuestas.`,
    }
    const body: UnsubscribePayload = {
      externalId,
      token
    };
    if (channel) body.channel = channel;
    const fetchOpts: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    };
    let URL = `${this.baseUrl}/contact/unsubscribe`;
    const fetchResponse: Response = await fetch(URL, fetchOpts);
    await fetchResponse.json();
    return resultMessage;
  };

}

export default new ContactService();